<template>
  <v-container
    id="login"
    class="fill-height justify-center"
    fluid
    tag="section"
    style="background-color: #242424"
  >
    <v-row justify="center">
        <v-col cols="4" sm="6" xs="10">

            <v-stepper v-model="step">
                <v-stepper-header>
                    <v-stepper-step 
                                    :complete="step > 1"
                                    step="1"
                                    >
                        Login
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="step > 3"
                                    step="2">
                        Verify
                    </v-stepper-step>
                    <v-divider></v-divider>
                </v-stepper-header>


                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-card class="mb-12"
                                color="white lighten-1"
                                height="200px"
                                >

                            <v-slide-y-transition appear>

                                <v-form ref="form" v-model="valid" lazy-validation>
                                    <v-card-text class="text-center">
                                        <v-text-field color="secondary"
                                                      label="Email..."
                                                      prepend-icon="mdi-email"
                                                      v-model="email"
                                                      :rules="emailRules"
                                                      class="mt-4"
                                                      required
                                                      outlined />

                                        <v-text-field class="mb-8"
                                                      color="secondary"
                                                      label="Password..."
                                                      v-model="password"
                                                      :rules="passwordRules"
                                                      required
                                                      outlined
                                                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                                      @click:append="() => (show1 = !show1)"
                                                      :type="show1 ? 'text' : 'password'"
                                                      prepend-icon="mdi-lock-outline" />
                                    </v-card-text>
                                </v-form>
                            </v-slide-y-transition>

                        </v-card>
                        <v-btn large
                               color=""
                               depressed
                               class="v-btn--text primary--text"
                               :disabled="!valid || loggingIn"
                               submit
                               @click="submit">
                            Login
                        </v-btn>                       
                    </v-stepper-content>
                    <v-stepper-content step="2">
                        <v-card class="mb-12"
                                color="white lighten-1"
                                height="200px">
                            <v-slide-y-transition appear>
                                <v-text-field class="mb-8"
                                              color="secondary"
                                              label="Verification Code..."
                                              v-model="verificationcode"
                                              required
                                              outlined
                                              prepend-icon="mdi-lock-outline" />
                            </v-slide-y-transition>

                        </v-card>
                        <v-btn large
                               color=""
                               depressed
                               class="v-btn--text primary--text"
                               :disabled="!valid || loggingIn"
                               submit
                               @click="submit"
                               >
                            Submit
                        </v-btn>

                        <v-btn large
                               color=""
                               depressed
                               class="v-btn--text primary--text"
                               :disabled="!valid || loggingIn"
                               submit
                               @click="back">
                            Back
                        </v-btn>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "login",
  data: () => ({
      valid: true,
      verificationcode: "",
      login: null,
     step: 1,
    password: "",
    responseMessage: "",
    show1: false,
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) =>
        (v && v.length <= 50) || "Password must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    checkbox: true,
    loggingIn: false,
  }),
  computed: {
    isAuthenticated() {
      return this.$store.getters.auth.isAuthenticated;
    },
    isLoading() {
      return this.$store.getters.auth.isLoading;
    },
  },
  methods: {
      async submit() {
          if (this.step === 1) {
              this.$refs.form.validate();
              if (this.$refs.form.validate(true)) {
                  this.loggingIn = true;
                  await this.$store.dispatch("auth/validate_credentials", {
                      EmailAddress: this.email,
                      Password: this.password,
                  }).then(response => {
                      if (response && response.succeeded) {
                          this.step = 2;
                          return this.$store.commit('snack/set_snack', 'Verification email sent');
                      }
                      else if (response && !response.succeeded) {
                          return this.$store.commit('snack/set_snack', 'Incorrect email or/and password.');
                      }
                      else if (response.lockedOut){
                          
                          return this.$store.commit('snack/set_snack', 'Too many attepmps, please try again later.');
                      }
                      })
                      .catch(error => {
                          return this.$store.commit('snack/set_snack', 'Incorrect email or/and password.');
                      })
                      .finally(() =>
                          this.loggingIn = false);
              }
          }
          else {
              await this.$store.dispatch("auth/login", {
                  EmailAddress: this.email,
                      Password: this.password,
                  verificationCode: this.verificationcode
              })
          }
      },
      async back() {
          this.step = 1;
      },
  },
  mounted: function() {
  },
};
</script>
